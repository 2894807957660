import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Cloud First Solutions</h2>
                                    <p>Our software solutions are 100% cloud based giving you the added flexibility of paying for what you use. We provide the follwing cloud solutions : </p>
                                    <ul className="about-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Cloud Databases
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Website Hosting
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            File Storage
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            DevOps for Cloud
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            theParse NoSQL Store
                                        </li>
                                        {/* <li>
                                            <i className="flaticon-tick"></i>
                                            Remote Desktop
                                        </li> */}
                                        {/* <li>
                                            <i className="flaticon-tick"></i>
                                            Email Servers
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Hybrid Cloud
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Design & Development</h2>
                                    <p>Our seasoned team of professionals has successfully undertaken a diverse array of projects spanning Website and Mobile Development, DevOps, Big Data, Internet of Things, as well as Machine Learning and Artificial Intelligence. This rich experience equips us with the proficiency to meticulously oversee your project through its various phases and construct a comprehensive software ecosystem. Our aim is to adeptly manage and enhance your business's operations and expansion using a suite of powerful software tools.</p>
                                    <ul className="our-mission-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Software Development
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Software Adaptation
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Software Integration
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Web Development
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Data Science
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            theParse-edge
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Software R & D
                                        </li>
                                         <li>
                                            <i className="flaticon-tick"></i>
                                            eCommerce Development
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;