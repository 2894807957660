import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70" id="what-we-do">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Our Solutions
                    </span>
                    <h2>We Embrace Uniqueness</h2>
                    <p>Our dynamic team comprises experts hailing from diverse software backgrounds, brought together by a shared ardor for the world of software.</p>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Business and Process Optimisation
                                </Link>
                            </h3>
                            <p>We focus on understanding your business needs. Our team of creative, innovative and passionate engineers and software developers focus on deriving solutions to challenges your business encounters aswell as exploring, identify and recommending ways in which your business can position itself for digital transformation. </p>
                            <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
                            <h3>
                                <Link to="/service-details-api">
                                    End-to-End Software Solutions 
                                </Link>
                            </h3>
                            <p>Our digtal maturity framework allows us to define a comprehensive business case for digital transformation. Our goal is to first understand your business and recommend ways of improving based on it's digital maturity level. Our experience and knowledge supports our innovative approach to developing end-to-end software solutions.</p>
                            <Link to="/service-details-api" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>
                            <h3>
                                <Link to="/service-details-ds">
                                    Data Driven Mindset
                                </Link>
                            </h3> 
                            <p>At the heart of what we do are our people. With a data driven mindset, our people assist in attaining a deeper understanding of your business using data-driven techniques. Our team of agile experts then focus on delivering an MVP within 6 months</p>
                            <Link to="/service-details-ds" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;