import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon4.png'
import serviceIcon5 from '../../assets/images/services/service-icon5.png'
import serviceIcon6 from '../../assets/images/services/service-icon6.png'

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb" id="theparse-edge">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        Our Features
                    </span>

                    <h2>theParse-edge</h2>
                    <p>Custom edge technology solutions for home and business. theParse-edge is a team of edge experts who focus on scaling theParse software solutions on edge devices.</p>
                </div>

                <div className="row">
                    {/* <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="feature" />
                            </div>
                            <h3>Email Notifications</h3>
                            <p>Our out of the box software solution has email integration giving you the flexibility to automate communication with employees.</p>
                        </div>
                    </div> */}
                    
                    {/* <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon4} alt="feature" />
                            </div>
                            <h3>Information Retrieval</h3>
                            <p>At theParse we are skilled at information retrieval. Turn data into insight today!</p>
                        </div>
                    </div> */}
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon5} alt="feature" />
                            </div>
                            <h3>Edge Analytics</h3>
                            <p>Take action on the edge with a tailored software solution for your business.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="feature" />
                            </div>
                            <h3>Egde Streaming</h3>
                            <p>Connect to sources on the edge using in-house developed connectors. Our connectors can collect data from more than 50 unique structured and un-structured data sources.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="feature" />
                            </div>
                            <h3>Edge Sync</h3>
                            <p>Our edge Sync solution allows for real-time data persistance to a central store that allows for search at scale.</p>
                        </div>
                    </div>
                    
                    {/* <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon6} alt="feature" />
                            </div>
                            <h3>Resource Management</h3>
                            <p>Looking for a low-cost solution to better manage your staff, your resources and capabilities?</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default OurFeatures